'use strict';

import angular from "angular";

angular.module('config', [])

  .constant('ENV', {
    'APPLICATION_URL': 'https://hca.staging.complyos.com',
    'COMPLYOS_API_URL': 'https://api.staging.complyos.com',
    'COMPLYOS_DOMAIN': 'staging.complyos.com',
    'COMPLYOS_FORMS_URL': 'https://forms.staging.complyos.com/#',
    // 'gaCode': 'UA-55932200-4',
    'NAME': 'staging',
  });
